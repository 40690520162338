<template>
    <fragment>
        <page-header :title="$t('dashboard.title')" icon="savings"> </page-header>
        <v-container>
            <title-sheets>
                <title-sheet :title="$t('dashboard.titles.projected', { thisMonthName })" subtitle>
                    <template #default>
                        {{ expectedThisMonth | currency(tenantStore.currencyCode) }}
                    </template>
                    <template #subtitle>
                        <span :class="percentageDownFromLastMonth < 0 ? 'success--text' : 'error--text'">
                            <comparison-icon :value="expectedThisMonth" :compare="totalLastMonth"></comparison-icon>
                            {{
                                $t(percentageDownFromLastMonth < 0 ? 'dashboard.lessThan' : 'dashboard.moreThan', {
                                    percentage: percentageDownFromLastMonth,
                                    month: lastMonthName,
                                })
                            }}
                        </span>
                    </template>
                </title-sheet>
                <title-sheet
                    :title="$t('dashboard.titles.totalLastMonth', { lastMonthName })"
                    :subtitle="lastMonthSubtitle"
                >
                    <template #default>
                        {{ totalLastMonth | currency(tenantStore.currencyCode) }}
                    </template>
                </title-sheet>
                <title-sheet
                    v-if="tenantStore.features.includes('Recommendations/View')"
                    :title="$t('dashboard.titles.potentialSavings')"
                    subtitle
                    @click.native="handleSavingsClicked"
                    class="hover_pointer"
                >
                    <template #default>
                        {{ (recommendationSummary.maximumEstimateImpact || 0.0) | currency(tenantStore.currencyCode) }}
                        <span v-if="recommendationSummary.includesElevatedSavings === true">*</span>
                    </template>
                    <template #subtitle>
                        <credential-status-icon
                            :value="recommendationStatus ? recommendationStatus.credentialStatus : null"
                        ></credential-status-icon>
                        {{ recommendationStatusText }}
                    </template>
                </title-sheet>
                <v-col cols="12" md="4" v-else>
                    <v-sheet class="py-4" style="position: relative">
                        <div class="text-center grey--text">
                            <br />
                        </div>
                        <div class="text-h4 text-center">
                            <br />
                        </div>
                        <div class="text-center subtitle-2 grey--text">
                            <br />
                        </div>
                    </v-sheet>
                </v-col>
            </title-sheets>
            <content-card
                :title="chartTitle"
                title-icon="savings"
                :loading="$wait.is('getTenantMonthlyUsage')"
                :caption="
                    $t('dashboard.monthAverage', {
                        total: averageMonthlySpend.toFixed(2),
                        currency: tenantStore.currencyCode,
                    })
                "
            >
                <template #title-actions>
                    <DateRangeButtonToggle
                        :value="{
                            dateRangeKey: usageOptions.dateRangeKey,
                            toDate: usageOptions.toDate,
                            fromDate: usageOptions.fromDate,
                        }"
                        @input="handleUpdateDateRange"
                        :items="['3m', '6m', '13m']"
                    ></DateRangeButtonToggle>
                    <v-btn-toggle v-model="figureType" class="mx-2">
                        <v-btn small depressed outlined value="charges"> Gross / Credits </v-btn>
                        <v-btn small depressed outlined value="spend"> Net Spend </v-btn>
                    </v-btn-toggle>
                </template>
                <SpendUsageBarChart
                    :max-date="maxDate"
                    :data="newChartData"
                    :granularity="usageOptions.granularity"
                    @click="handleDatasetClicked"
                ></SpendUsageBarChart>
            </content-card>
            <content-card
                :title="$t('dashboard.usageByHeirarchy.title')"
                title-icon="sell"
                :caption="$t('dashboard.usageByHeirarchy.caption')"
                class="mt-3"
            >
                <template #title-actions v-if="tenantStore.hasPermission('CanAdminister')">
                    <v-btn depressed text @click="onManageHierarchyClick">
                        <v-icon left>settings</v-icon>
                        {{ $t('dashboard.usageByHierarchy.manageHierarchy') }}
                    </v-btn>
                </template>
                <SpendUsageTable
                    :items="tagTableItems"
                    :segregate-by="tagOptions.segregateBy"
                    :currency-code="tenantStore.currencyCode"
                    class="mt-3"
                    hide-intelligence
                    prefix="/finance"
                >
                </SpendUsageTable>
            </content-card>
        </v-container>

        <form-dialog v-model="dialogs.updateTenant" #default="{ close }">
            <set-tenant-card
                :value="tenantToEdit"
                :loading="$wait.is('updatingTenant')"
                :title="$t('dialog.manageTenants.edit.title')"
                :subtitle="$t('dialog.manageTenants.edit.subtitle')"
                :nameEditable="false"
            >
                <template #actions="{ isValid, form, formId, loading }">
                    <back-button @click="close"></back-button>
                    <v-spacer></v-spacer>
                    <put-tenant-button
                        :value="{ ...tenantToEdit, ...form }"
                        :valid="isValid"
                        :loading="loading"
                        :form="formId"
                        @http2xx="close().then(() => afterUpdateTenant({ ...tenantToEdit, ...form }))"
                    >
                    </put-tenant-button>
                </template>
            </set-tenant-card>
        </form-dialog>

        <router-view @input="handleDialogChange" />
    </fragment>
</template>

<i18n>
{
  "en": {
    "dashboard.title": "Cost and Usage",
    "dashboard.accountOverview": "Account Overview",
    "dashboard.titles.projected": "Projected {thisMonthName}",
    "dashboard.titles.totalLastMonth": "Total {lastMonthName}",
    "dashboard.titles.potentialSavings": "Potential Monthly Savings",
    "dashboard.yearInReview": "Year in review",
    "dashboard.monthAverage": "{total} {currency} / Month Average",
    "dashboard.titles.creditsLastMonth": "Excludes {credits} of credits and discounts",
    "dashboard.usageByHeirarchy.title": "Usage by hierarchy",
    "dashboard.usageByHeirarchy.caption": "Aggregate spend across the tenant hierarchy over the last 3 months",
    "dashboard.lessThan": "{percentage}% less than {month}",
    "dashboard.moreThan": "{percentage}% more than {month}",
    "dashboard.recommendationStatus.loading": "Loading...",
    "dashboard.recommendationStatus.unknown": "Unknown status",
    "dashboard.recommendationStatus.unavailable": "Unavailable",
    "dashboard.recommendationStatus.allGood": "All good",
    "dashboard.recommendationStatus.partiallyGood": "New credentials required",
    "dashboard.recommendationStatus.actionRequired": "Connection broken",
    "dashboard.usageByHierarchy.manageHierarchy": "Manage hierarchy",
    "dialog.manageTenants.edit.title": "Update Tenant",
    "dialog.manageTenants.edit.subtitle": "Update the details of this tenant."
  }
}
</i18n>

<script>
import { getSpend, getForecast } from '@/lib/Api';
import ApiV2 from '@/lib/ApiV2.ts';
import { waitFor } from '@/plugins/vue-wait';
import moment from '@/lib/moment';
import CredentialStatusIcon from '@/components/Settings.CloudAccounts/CredentialStatusIcon.vue';
import SpendUsageBarChart, {
    convertProjectionsResponseToDataset,
    convertUsageResponseToDatasets,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import DateRangeButtonToggle from '@/components/Domain.Usage/DateRangeButtonToggle.vue';
import { toastError } from '@/components/Common/Toast.vue';
import SetTenantCard from '@/components/Domain.Tenants/SetTenantCard.vue';
import PutTenantButton from '@/components/Domain.Tenants/PutTenantButton.vue';
import { formatCurrency } from '@/lib/Currencies';
import { useTenantStore } from '@/stores/tenant.ts';
import { mapStores } from 'pinia';
import { convertSpendOptionsToQuery, calculateOptionsDescription } from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import { Granularity, SpendFieldNames } from '@/models';
import DateKey, { granularityForDateRange } from '@/lib/DateKey';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';

export default {
    props: ['me'],
    title(ctx) {
        return ctx.$t('dashboard.title');
    },
    components: {
        CredentialStatusIcon,
        SpendUsageBarChart,
        SetTenantCard,
        PutTenantButton,
        DateRangeButtonToggle,
        SpendUsageTable
    },
    data() {
        return {
            figureType: 'charges',
            newChartData: { labels: [], datasets: [] },
            tenantMonthlyUsageResponse: {},
            totalSpendForYear: 0.0,
            averageMonthlySpend: 0.0,
            projectedThisMonth: 0.0,
            totalLastMonth: 0.0,
            totalThisMonth: 0.0,
            creditsLastMonth: 0.0,
            cloudAccounts: [],
            topXServices: [],
            thisMonthName: moment.utc().format('MMMM'),
            lastMonthName: moment.utc().add(-1, 'months').format('MMMM'),
            recommendationSummary: {},
            recommendationStatus: {},
            tagTableItems: null,
            usageRecords: [],
            projections: [],
            fromDate: null,
            maxDate: null,
            usageOptions: {
                dateRangeKey: '6m',
                granularity: Granularity.monthly,
                topXResults: 10,
                segregateBy: 'cloudaccounts',
                costView: 'Utilized',
            },
            tagOptions: {
                dateRangeKey: '3m',
                segregateBy: '',
                granularity: Granularity.monthly
            },
            tenantToEdit: null,
            dialogs: {
                updateTenant: false,
            },
        };
    },
    created() {
        this.getCloudAccounts();
        this.getRecommendationSummary();
        this.getRecommendationStatus();
    },
    watch: {
        me: {
            handler(val) {
                if (!val || !val.tenant) return;
                if (val.tenant.tagHeirarchy && val.tenant.tagHeirarchy.length){
                    this.getTagsOverview(val.tenant.tagHeirarchy);
                }
            },
            immediate: true,
        },
        usageOptions: {
            handler(options) {
                if (!options) return;
                if (this.$wait.is('getTenantMonthlyUsage')) return;
                this.getUsageForTenant(options);
            },
            immediate: true,
            deep: true,
        },
        figureType: {
            handler() {
                this.getUsageForTenant(this.usageOptions);
            },
        },
    },
    computed: {
        expectedThisMonth() {
            return this.totalThisMonth + this.projectedThisMonth;
        },
        percentageDownFromLastMonth() {
            if (!this.totalLastMonth) return 0;
            return -1 * (100 - (100 / this.totalLastMonth) * this.expectedThisMonth).toFixed(2);
        },
        lastMonthSubtitle() {
            const lastMonth = moment.utc().add(-1, 'months');
            const nocreditsTxt = `${lastMonth.format('MMMM')} 1-${lastMonth.daysInMonth()}`;
            const creditsValue = formatCurrency(this.creditsLastMonth, this.tenantStore.currencyCode);
            return this.creditsLastMonth > 0
                ? this.$t('dashboard.titles.creditsLastMonth', { credits: creditsValue })
                : nocreditsTxt;
        },
        recommendationStatusText() {
            if (this.$wait.is('getRecommendationStatus') || !this.recommendationStatus) {
                return this.$t('dashboard.recommendationStatus.loading');
            }
            const value = this.recommendationStatus.credentialStatus;
            if (value === undefined) {
                return this.$t('dashboard.recommendationStatus.unknown');
            }
            if (value === 0 || value === 'Unavailable') {
                return this.$t('dashboard.recommendationStatus.unavailable');
            }
            if (value === 1 || value === 'AllGood') {
                return this.$t('dashboard.recommendationStatus.allGood');
            }
            if (value === 2 || value === 'PartiallyGood') {
                return this.$t('dashboard.recommendationStatus.partiallyGood');
            }
            if (value === 3 || value === 'ActionRequired') {
                return this.$t('dashboard.recommendationStatus.actionRequired');
            }
            return this.$t('dashboard.recommendationStatus.unknown');
        },
        chartTitle() {
            if (this.usageOptions.dateRangeKey === '13m') {
                return this.$t('dashboard.yearInReview');
            } else {
                return calculateOptionsDescription(this.usageOptions, (...params) => this.$t(...params));
            }
        },
        ...mapStores(useTenantStore),
    },
    methods: {
        getUsageForTenant: waitFor('getTenantMonthlyUsage', async function getUsageForTenant(options) {
            const usageParams = convertSpendOptionsToQuery(options);
            if (!usageParams) return;
            const fields =
                this.figureType === 'charges'
                    ? [SpendFieldNames.Charges, SpendFieldNames.Credits]
                    : [SpendFieldNames.Spend, SpendFieldNames.Credits];
            const usageResponse = await getSpend({ ...usageParams, costView: 'Actual', fields });
            const usageDataset = convertUsageResponseToDatasets(usageResponse, {
                labelSuffix: this.figureType === 'charges' ? 'Gross Charges' : 'Net Spend',
                reportType: [this.figureType],
            });
            let creditsDataset =
                this.figureType === 'charges'
                    ? convertUsageResponseToDatasets(usageResponse, {
                          labelSuffix: 'Total Credits',
                          reportType: ['credits'],
                          colorsArray: ['20,115,228'],
                          stackGroup: 'credits',
                      })
                    : { datasets: [], labels: [] };

            if (!creditsDataset.datasets.find((d) => d.data.find((d) => d.y > 0))) {
                creditsDataset = { datasets: [], labels: [] };
            }
            this.newChartData = {
                labels: [...usageDataset.labels, ...creditsDataset.labels],
                datasets: [...usageDataset.datasets, ...creditsDataset.datasets],
            };

            const projectionsResponse = await getForecast({ ...usageParams, costView: 'Actual' }, options.dateRangeKey);
            const projectionDataset = convertProjectionsResponseToDataset(projectionsResponse, 'Net Spend');
            this.newChartData = {
                labels: [...projectionDataset.labels, ...usageDataset.labels, ...creditsDataset.labels],
                datasets: [...projectionDataset.datasets, ...usageDataset.datasets, ...creditsDataset.datasets],
            };

            if (this.tenantStore.hasTenantFeature('ViewAmortisedSpend')) {
                const [usageUtilized, projectionUtilized] = await Promise.all([
                    getSpend({ ...usageParams, costView: 'Utilized', fields }),
                    getForecast({ ...usageParams, costView: 'Utilized' }, options.dateRangeKey),
                ]);
                const utilizedUsageDataset = convertUsageResponseToDatasets(usageUtilized, {
                    labelSuffix: 'Utilized Spend',
                    stackGroup: 'utilized',
                    colorsArray: ['0,115,128'],
                    reportType: [this.figureType],
                });
                const utilizedProjectionDataset = convertProjectionsResponseToDataset(
                    projectionUtilized,
                    'Utilized Spend',
                    'utilized',
                    '#049AB5'
                );
                this.newChartData = {
                    labels: [
                        ...projectionDataset.labels,
                        ...usageDataset.labels,
                        ...creditsDataset.labels,
                        ...utilizedUsageDataset.labels,
                        ...utilizedProjectionDataset.labels,
                    ],
                    datasets: [
                        ...projectionDataset.datasets,
                        ...usageDataset.datasets,
                        ...creditsDataset.datasets,
                        ...utilizedUsageDataset.datasets,
                        ...utilizedProjectionDataset.datasets,
                    ],
                };
            } else {
                this.newChartData = {
                    labels: [...projectionDataset.labels, ...usageDataset.labels, ...creditsDataset.labels],
                    datasets: [...projectionDataset.datasets, ...usageDataset.datasets, ...creditsDataset.datasets],
                };
            }
            this.fromDate = new Date(usageParams.fromDate);
            this.maxDate = projectionsResponse?.maxUsageDate || usageParams.toDate

            const tm = moment.utc();
            const lm = moment.utc().add(-1, 'months');
            this.averageMonthlySpend = usageResponse.recordAverage || this.averageMonthlySpend || 0.0;
            this.totalSpendForYear = usageResponse.yearlyTotals?.[tm.format('YYYY')] || this.totalSpendForYear || 0.0;
            this.totalLastMonth = usageResponse.monthlyCharges?.[lm.format('YYYY')]?.[lm.format('MM')] || this.totalLastMonth || 0.0;
            this.creditsLastMonth = usageResponse.monthlyCredits?.[lm.format('YYYY')]?.[lm.format('MM')] || this.creditsLastMonth || 0.0;
            this.totalThisMonth = usageResponse.monthlyCharges?.[tm.format('YYYY')]?.[tm.format('MM')] || this.totalThisMonth || 0.0;
            const thisMonthForecast = projectionsResponse.forecasts.find((f) =>
                tm.isSame(moment.utc(f.usageDate), 'month')
            );
            if (thisMonthForecast) this.projectedThisMonth = thisMonthForecast.forecast;
        }),
        getCloudAccounts: waitFor('getCloudAccounts', async function getCloudAccounts() {
            this.cloudAccounts = await ApiV2.http.get('/api/metadata/cloudaccounts').then((r) => r.data);
        }),
        getRecommendationSummary: waitFor('getRecommendationSummary', async function getRecommendationSummary() {
            if (this.tenantStore.features.includes('Recommendations/View')) {
                this.recommendationSummary = await ApiV2.http
                    .get('/api/recommendations/summary')
                    .then((r) => r.data.data);
            } else {
                this.recommendationSummary = {};
            }
        }),
        getRecommendationStatus: waitFor('getRecommendationStatus', async function getRecommendationStatus() {
            if (this.tenantStore.features.includes('Recommendations/View')) {
                this.recommendationStatus = await ApiV2.http
                    .get('/api/recommendations/credentialstatus')
                    .then((r) => r.data);
            } else {
                this.recommendationStatus = {};
            }
        }),
        getTagsOverview: waitFor('getTagsOverview', async function getTagsOverview(heirarchy) {
            try {
                if (!heirarchy?.length) return;
                const params = convertSpendOptionsToQuery(this.tagOptions);
                if (!params) return;

                const segregatedBy = heirarchy.map(h => `tags/${h}`);

                this.tagOptions.segregateBy = segregatedBy.join(',');

                const usageResponse = await getSpend({
                    ...params,
                    segregateBy: segregatedBy,
                    fields: [SpendFieldNames.Charges, SpendFieldNames.Spend],
                });

                this.tagTableItems = SpendUsageTableFunctions.convertToItemRecords(
                    params,
                    segregatedBy,
                    usageResponse,
                );
            } catch (err) {
                toastError('There was an issue loading the data.');
            }
        }),
        onManageHierarchyClick() {
            this.tenantToEdit = {
                ...this.me.tenant,
                tagHeirarchy: [...this.me.tenant.tagHeirarchy],
            };
            this.dialogs.updateTenant = true;
        },
        afterUpdateTenant(updatedTenant) {
            this.$emit('tenant-update', updatedTenant);
            this.tenantStore.setTenantDetailsFromV1(updatedTenant);
            this.getTagsOverview(updatedTenant.tagHeirarchy);
        },
        handleSavingsClicked() {
            if (this.recommendationStatus.credentialStatus > 1) {
                this.$router.push('/settings');
            } else {
                this.$router.push('/recommendations');
            }
        },
        handleDatasetClicked({ datasetGroupId, isOther }) {
            if (isOther) {
                this.usageOptions.topXResults = this.usageOptions.topXResults + 10;
            } else {
                this.$router.push(`/finance/${this.usageOptions.segregateBy}/${datasetGroupId}`);
            }
        },
        handleDialogChange(isOpen) {
            if (isOpen) return;
            this.$router.push('/finance');
            this.$title = this.$t('dashboard.title');
        },
        handleUpdateDateRange($event) {
            const { dateRangeKey, fromDate, toDate } = $event;
            const key = new DateKey(dateRangeKey);
            const dateRange = key.getEffectiveDateRange(fromDate, toDate);
            const granularity = granularityForDateRange(dateRange);
            this.usageOptions.granularity = granularity;
            this.usageOptions.dateRangeKey = dateRangeKey;
            this.usageOptions.fromDate = fromDate;
            this.usageOptions.toDate = toDate;
        }
    },
};
</script>

<style>
.hover_pointer {
    cursor: pointer;
}
</style>
