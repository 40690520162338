
import { defineComponent } from 'vue';
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import Downloader from '@/lib/Downloader';
import moment from '@/lib/moment';
import { PluralEntityType } from '@/models';
import SpendUsageBarChart, {
    convertUsageResponseToDatasets,
    UsageReportTypes,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import SpendUsageOptionsToolbarForm, {
    convertSpendOptionsToQuery,
    CostView,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import { useTenantStore } from '@/stores';
import { mapStores } from 'pinia';

export default defineComponent({
    title(ctx) {
        return ctx.$t('reservationsDashboard.title');
    },
    components: { SpendUsageBarChart, SpendUsageOptionsToolbarForm },
    computed: {
        ...mapStores(useTenantStore),
        chartTitle() {
            return 'Reservation Usage';
        },
        tableEntities() {
            if (!this.reservations) return [];
            return this.reservations.map((i) => {
                const startDate = moment.utc(i.startDate);
                const record = {
                    ReservationId: i.reservationId,
                    CloudAccount: i.cloudAccountName,
                    CommitmentType: i.commitmentType,
                    CommitmentId: i.commitmentId,
                    ProductName: i.productName,
                    ProviderType: i.providerType,
                    Quantity: i.quantity,
                    Region: i.region,
                    PaymentOption: i.paymentOption,
                    ThirtyDayUtil: i.thirtyDayUtilisation,
                    StartDate: startDate.format('DD MMM YYYY'),
                };
                return record;
            });
        },
        headers() {
            return [
                {
                    text: this.$t('reservationsDashboard.headers.cloudAccountName'),
                    value: 'CloudAccount',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.commitmentType'),
                    value: 'CommitmentType',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.commitmentId'),
                    value: 'CommitmentId',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.productName'),
                    value: 'ProductName',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.quantity'),
                    value: 'Quantity',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.region'),
                    value: 'Region',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.paymentOption'),
                    value: 'PaymentOption',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.startDate'),
                    value: 'StartDate',
                    sortable: true,
                },
                {
                    text: this.$t('reservationsDashboard.headers.thirtyDayUtil'),
                    value: 'ThirtyDayUtil',
                    sortable: true,
                },
            ];
        },
    },
    created() {
        this.loadReservations();
    },
    data() {
        return {
            reservations: [],
            usageOptions: {
                dateRangeKey: '60d',
                granularity: 'Daily',
                topXResults: 500000,
                segregateBy: PluralEntityType.reservations,
                costView: CostView.Utilized
            },
            newChartData: { labels: [], datasets: [] },
            maxDate: undefined
        };
    },
    methods: {
        loadReservations: waitFor('loadReservations', async function loadReservations() {
            const params = {};
            const response = await ApiV2.http.get(`/api/reservations`, { params }).then((r) => r.data);
            this.reservations = response;
        }),
        exportCsv() {
            const headers = [
                { text: this.$t('reservationsDashboard.headers.commitmentType'), value: 'commitmentType' },
                { text: this.$t('reservationsDashboard.headers.commitmentId'), value: 'commitmentId' },
                { text: this.$t('reservationsDashboard.headers.productName'), value: 'productName' },
                { text: this.$t('reservationsDashboard.headers.quantity'), value: 'quantity' },
                { text: this.$t('reservationsDashboard.headers.region'), value: 'region' },
                { text: this.$t('reservationsDashboard.headers.paymentOption'), value: 'paymentOption' },
                { text: this.$t('reservationsDashboard.headers.startDate'), value: 'startDate' },
                { text: this.$t('reservationsDashboard.headers.thirtyDayUtil'), value: 'thirtyDayUtilisation' },
            ];

            const headerValues = headers.map((i) => i.value);
            const csvValues = this.reservations.map((i) =>
                headerValues.map((k) => (k == 'productName' ? i[k].replace(/,/g, '') : i[k]))
            );
            csvValues.unshift(headers.map((i) => i.text));
            Downloader.exportCSV(csvValues, 'reservations.csv');
        },
        handleDatasetClicked(data) {
            const reservation = this.reservations.find(r => r.remoteId === data.datasetGroupId);
            if (!reservation) return;
            this.$router.push(`/reservations/${reservation.reservationId}`);
        },
        getUsageRecords: waitFor('gettingUsageRecords', async function getUsageRecords(options) {
            const params = convertSpendOptionsToQuery(options);
            if (!params) return;
            if (this.usageOptions.segregateBy === 'tags') {
                params.tagKeyValue = this.tagKey ? `${this.tagKey}:` : null;
            }
            const usageResponse = await ApiV2.http
                .get(
                    `/api/spend/${options.segregateBy}` +
                        (options.segregateBy == 'tags' ? `/${encodeURIComponent(this.tagKey)}` : ``),
                    { params }
                )
                .then((r) => r.data);
            this.fromDate = new Date(params.fromDate);
            this.maxDate = params.toDate;
            const usageDataset = convertUsageResponseToDatasets(usageResponse, {
                reportType: UsageReportTypes.charges,
            });
            this.newChartData = usageDataset;
        }),
        handleDialogChange(isOpen) {
            if (isOpen) return;
            this.$router.push('/reservations');
            this.$title = this.$t('reservationsDashboard.title');
        },
    },
    watch: {
        usageOptions: {
            handler(options) {
                if (!options) return;
                if (this.$wait.is('gettingUsageRecords')) return;
                this.getUsageRecords(options);
            },
            immediate: true,
            deep: true,
        },
    },
});
