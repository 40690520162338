
import { defineComponent } from 'vue';
import ApiV2 from '@/lib/ApiV2';
import Config from '@/lib/Config';
import AuthClient from '@/lib/Auth';
import { getInstance } from '@/plugins/auth/auth0Instance';
export default defineComponent({
    data: () => ({
        isAuthenticated: false,
        needSupport: false,
        loading: false,
        supportEmail: Config.get().SUPPORT_EMAIL,
    }),
    methods: {
        async startLogin() {
            const auth0 = await getInstance();
            await auth0.loginWithRedirect({
                provider: '',
            });
        },
        async startRegister() {
            const auth0 = await getInstance();
            await auth0.loginWithRedirect({
                provider: '',
                screen_hint: 'signup'
            });
        },
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            async handler(route) {
                this.isAuthenticated = AuthClient.isAuthenticated;

                if (this.isAuthenticated) {
                    try {
                        this.loading = true;
                        await ApiV2.http.post('/api/awsmarketplace/redeem', { marketplaceToken: route.query.token });
                        this.$router.push('/choose');
                    } catch {
                        this.needSupport = true;
                    } finally {
                        this.loading = false;
                    }
                }
            },
        },
    },
});
