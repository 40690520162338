
import { useTenantStore, useWaitStore, storeToRefs, useFilterStore } from '@/stores';
import SpendUsageBarChart, {
    convertUsageResponseToDatasets,
    IClickEvent,
    UsageReportTypes,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import SpendUsageOptionsToolbarForm, {
    SpendUsageOptions,
    convertSpendOptionsToQuery,
    CostView,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import UsageFilterChips, {
    filterUsageParamsByFilterChips,
    IChipViewModel,
} from '@/components/Domain.Usage/UsageFilterChips.vue';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import { toastError } from '@/components/Common/Toast.vue';
import { defineComponent, ref, watch } from 'vue';
import { getUsage } from '@/lib/Api';
import { fromPlural } from '@/models/EntityType';
import { EntityType, PluralEntityType, ISpendUsageTableRecordsViewModel } from '@/models';
import { i18n } from '@/i18n';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';
import { UnitOfDisplay } from '@/enums/UnitOfDisplay.enum';

export default defineComponent({
    components: {
        SpendUsageOptionsToolbarForm,
        UsageFilterChips,
        SpendUsageTable,
        SpendUsageBarChart,
    },
    props: { value: Object, loading: Boolean },
    setup(p) {
        const unitOfDisplay = ref(UnitOfDisplay.cost);
        const { currencyCode } = storeToRefs(useTenantStore());
        const { is, whilst } = useWaitStore();
        const filterStore = useFilterStore();
        filterStore.setFilter({segregateBy: PluralEntityType.services, costView: CostView.Utilized})
        const spendOptions = ref<SpendUsageOptions>(filterStore.options);
        const newChartData = ref({ labels: [], datasets: [] });
        let maxDate: string = undefined;
        const usageTableItems = ref<ISpendUsageTableRecordsViewModel | null>(null);
        const filters = ref<IChipViewModel[]>([]);
        const searchSupportedTypes = ref<EntityType[]>([EntityType.meter, EntityType.product]);
        const segregationSupportedKeys = ref<PluralEntityType[]>([PluralEntityType.meters, PluralEntityType.products]);

        const loadTabUsage = async () =>
            await whilst('getServiceUsage', async () => {
                const { reservationId } = p.value;
                const options: SpendUsageOptions = { ...spendOptions.value, reservationId };
                if (Array.isArray(options.segregateBy)) throw new Error('Multiple segregations unsupported!');
                const segregateBy = options.segregateBy;
                let params = convertSpendOptionsToQuery(options);
                params = filterUsageParamsByFilterChips(filters.value, params);
                if (!params) return;
                const usageResponse = await getUsage(segregateBy, params);
                const usageDataset = convertUsageResponseToDatasets(usageResponse, {
                    reportType: UsageReportTypes.charges,
                });                
                newChartData.value = {
                    labels: [...usageDataset.labels],
                    datasets: [...usageDataset.datasets],
                };
                maxDate = usageResponse.maxDate || params.toDate
                usageTableItems.value = SpendUsageTableFunctions.convertToItemRecords(
                    params,
                    segregateBy,
                    usageResponse,
                    options.costView
                );
            });

        const handleDatasetClicked = ({ datasetGroupId, isOther, name }: IClickEvent) => {
            if (isOther) {
                spendOptions.value.topXResults = spendOptions.value.topXResults + 10;
            } else if (spendOptions.value.segregateBy === PluralEntityType.meters) {
                toastError(i18n.t('shared.meterInformationNotSupported'));
            } else {
                filters.value.push({
                    id: datasetGroupId,
                    name,
                    type: fromPlural(spendOptions.value.segregateBy) as EntityType,
                });
            }
        };

        watch(
            () => [p.value, spendOptions.value, filters.value],
            () => {
                if (!p.value || !spendOptions.value || is('getUsage')) return;
                loadTabUsage();
            },
            { immediate: true, deep: true }
        );

        return {
            spendOptions,
            newChartData,
            usageTableItems,
            filters,
            searchSupportedTypes,
            segregationSupportedKeys,
            loadTabUsage,
            handleDatasetClicked,
            currencyCode,
            wait: { is },
            unitOfDisplay,
        };
    },
});
